.footer {
  width: 100%;
  float: left;
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 13px;
  background: #414545;
  background-image: url(../img/nav.jpg);
  background-size: cover;
  background-position: center center;

  p {
    margin: 5px 0;
    display: inline-block;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: $background;
      }
    }
  }

  .social {
    float: right;
    margin: 10px 0 0 0;
    list-style: none;
    display: inline-block;

    li {
      padding: 0 0 0 15px;
      display: inline-block;
      float: left;
      margin: 0;

      a {
        color: #fff;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $theme-secondary;
        }

        span {
          font-size: 25px;
          line-height: 40px;
        }
      }
    }
  }
}
