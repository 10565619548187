.preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $secondary;
  z-index: 99999;
  text-align: center;
  display: table;
  width: 100%;
  height: 100%;
}

.preloader-content {
  display: table-cell;
  vertical-align: middle;
}

.preloader-content img {
  display: block;
  margin: 0 auto 30px auto;
  width: 200px;
  height: auto;
}

.preloader .icon {
  display: inline-block;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid $primary;
  /* $theme-primary */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    @include rotate (0deg);
  }

  100% {
    transform: rotate(360deg);
    @include rotate (360deg);
  }

}