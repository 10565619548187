.auto-hide-header {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-bottom: 0;
  z-index: 1002;
  @include clearfix;
  background-color: rgba(255, 255, 255, 0.75);

  .top-space {
    width: 100%;
    float: left;
    text-align: right;
    color: #fff;
    background: $color-1;
    font-family: $button-font;
    display: block;

    @include MQ(SM) {
      display: block;
    }

    .phone-number-text {
      font-size: 1rem;
    }

    .flex-align {
      width: 100%;
      float: left;
      @include flexbox;
      @include flex-wrap($value: wrap);
      @include align-items($value: center);
      @include justify-content($value: space-between);
    }

    ul {
      display: inline-block;
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: 12px;
      float: left;

      @include MQ(SM) {
        font-size: 14px;

        &.social,
        &.languages {
          float: right;
        }
      }

      li {
        display: inline-block;
        line-height: 20px;
        margin: 0 5px 0 0;

        @include MQ(SM) {
          margin: 0 15px 0 0;
          line-height: 20px;
        }

        a {
          line-height: 20px;
          padding: 0;
          color: #fff;
          display: block;

          @include MQ(SM) {
            padding: 0 2px;
            line-height: 20px;
          }

          span {
            line-height: 30px;

            &:before {
            }
          }

          &:hover,
          &:focus {
            color: #fff;
            text-decoration: none;
          }
        }
      }

      &.social {
        li {
          margin-left: 0;

          a {
            font-size: 20px;
            padding: 0 2px;

            span {
              vertical-align: middle;
            }
          }
        }
      }

      &.lang {
        li {
          margin: 0 5px;

          span {
            display: none;
            font-weight: 200;
            @include MQ(SM) {
              display: inline-block;
            }

          }
        }
      }

    }
  }

  .top-menu {
    width: 100%;
    float: left;
    height: auto;

    @include MQ(M) {
      height: 90px;
    }
  }

  .container {
    position: relative;
  }

  .logo {
    float: left;
    display: inline-block;
    margin: 15px 0;

    a {
      display: block;
      height: 40px;
      width: auto;

      @include MQ(M) {
        height: 60px;
      }
    }

    .icon-logo {
      height: 40px;
      width: 175px;
      display: inline-block;
      background-image: url("../img/logo-PaelladorGroup.jpg");
      background-size: auto 40px;
      background-position: left center;
      background-repeat: no-repeat;

      @include MQ(M) {
        font-size: 60px;
        height: 60px;
        width: 175px;
        background-size: auto 60px;
      }
    }

    .logo-img {
      text-indent: -9999px;
      display: none;
    }

  }

  .primary-nav {
    display: inline-block;
    float: right;
    height: 100%;
    padding-right: 0;
    width: 100%;

    @include MQ(M) {
      width: auto;
    }

    .nav-trigger {
      position: absolute;
      top: 15px;
      display: table;
      height: auto;
      padding: 0 1em;
      font-size: 10px;
      text-transform: uppercase;
      color: $theme-primary;
      font-weight: bold;
      right: 10px;

      &:hover,
      &:focus,
      &.active {
        text-decoration: none;
      }

      span {
        display: table-cell;
        vertical-align: middle;
      }

      em,
      em::after,
      em::before {
        display: block;
        position: relative;
        height: 2px;
        width: 22px;
        background-color: $theme-primary;
        backface-visibility: hidden;
      }

      em {
        margin: 10px auto;
        transition: background-color .2s;
        background-color: rgba(red, 0);
      }

      em::before,
      em::after {
        position: absolute;
        content: "";
        left: 0;
        transition: transform .2s;
      }

      em::before {
        transform: rotate(-45deg);
        @include rotate(-45deg);
      }

      em::after {
        transform: rotate(45deg);
        @include rotate(45deg);
      }

      @include MQ(M) {
        display: none;
      }

    }

    .nav-trigger.collapsed {
      em {
        background-color: $theme-primary;
      }

      em::before {
        transform: translateY(-6px);
        @include translateY(-6px);
      }

      em::after {
        transform: translateY(6px);
        @include translateY(6px);
      }

    }

    > ul {
      z-index: 2;
      padding: 10px 0;
      margin: 0;
      list-style: none;

      li {
        margin: 0;

        &.current-menu-ancestor {
          a {
            color: $color-1;
          }
        }

        a {
          display: block;
          color: $color-1;
          text-transform: uppercase;

          &:hover,
          &:focus,
          &.active {
            color: #000;
            text-decoration: none;
          }

        }

        ul {
          li {
            a {
              font-weight: normal;
              font-size: 14px;
              padding: 5px 0;
            }
          }
        }
      }

      @include MQ(M) {
        padding: 0;
      }

    }

    @include MQ(M) {
      display: table;

      > ul {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        width: auto;
        top: 0;
        right: 0;
        padding: 0;
        @include clearfix;

        li {
          display: inline-block;
          float: left;

          &:last-of-type {
            margin-right: 0;
          }

          a {
            padding: 0 0 0 15px;
            border: none;
            line-height: 90px;
          }

          ul {
            li {
              a {
                padding: 5px 15px;
              }
            }
          }
        }
      }
    }
  }

  &.shrink {
    background: #fff;
    border-bottom: 1px solid #eee;
    position: fixed;

    .top-space {
      height: 0;
      overflow: hidden;
    }

    .top-menu {
      height: auto;
    }

    .logo {
      margin: 15px 0;

      a {
        height: 40px;

        @include MQ(M) {
          height: 60px;
        }
      }

      .icon-logo {
        height: 40px;
        width: 175px;
        background-image: url("../img/logo-PaelladorGroup.jpg");
        background-size: auto 40px;

        @include MQ(M) {
          font-size: 60px;
          height: 60px;
          width: 175px;
          background-size: auto 60px;
        }
      }

    }

    .primary-nav {

      .nav-trigger {
        color: $theme-primary;

        em {
          background-color: rgba(red, 0);
        }
      }

      .nav-trigger.collapsed {

        em,
        em::after,
        em::before {
          background-color: $theme-primary;
        }

      }

    }

    .primary-nav ul li a {
      color: #000;

      &:hover,
      &:focus,
      &.active {
        color: $theme-primary;
        text-decoration: none;
      }

    }

  }

  .dropdown-menu {
    padding: 0;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    width: 100%;

    @include MQ(M) {
      margin-top: 0 !important;
      position: absolute;
      width: auto;
    }

    li {
      display: block;
      width: 100%;

      a {
        padding: 5px 10px;
        line-height: 1.3 !important;
        font-size: 14px;

        @include MQ(M) {
          padding: 5px 15px;
          line-height: 1.3 !important;
        }
      }
    }

    li a:after {
      display: none;
    }

    li a:hover {
      background-color: $theme-primary;
      color: #fff !important;
    }
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    background-color: $theme-primary;
    color: #fff !important;
  }

}