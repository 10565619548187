@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Montserrat:300,600');

$theme-colors: (
        "primary": #0a0e6e,
);

$brand-primary: #0a0e6e !default;
$theme-primary: $brand-primary;
$theme-secondary: #333333;

$font-family-sans-serif: 'Montserrat', serif;
$font-family-serif: 'Libre Baskerville', serif;

$font-family-base: 'Montserrat', serif;
$title-font: 'Libre Baskerville', serif;
$button-font: 'Montserrat', serif;

$main-text: #424242; // main text
$link: $brand-primary; // anchor tags
$background: #fbfbfb; // body background color

$color-1: $brand-primary;
$color-2: $brand-primary;
$color-3: #008BCD; // azul claro
$color-4: #fff; // blanco
$color-5: #a3a09a; // gris claro

$black: #58595B;
$white: #fff;
$bg: #F3F2F0;
$title: #234688;

// bootstrap
$brand-primary: $color-1 !default;
$theme-primary: $color-1;

@mixin padding-left() {
  padding-left: 20px;

  @media(min-width: $screen-sm-min) {
    padding-left: 40px;
  }

  @media(min-width: $screen-md-min) {
    padding-left: 80px;
  }
}

$S: 480px;
$SM: 768px;
$M: 992px;
$L: 1200px;
$XL: 1800px;

// media queries
@mixin MQ($canvas) {
  @if $canvas==S {
    @media only screen and (min-width: $S) {
      @content;
    }
  }

  @if $canvas==SM {
    @media only screen and (min-width: $SM) {
      @content;
    }
  } @else if $canvas==M {
    @media only screen and (min-width: $M) {
      @content;
    }
  } @else if $canvas==L {
    @media only screen and (min-width: $L) {
      @content;
    }
  } @else if $canvas==XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}