.error-404 {
  text-align: center;
  width: 100%;
  height: 100vh;
  min-height: 100%;

  @include flexbox;
  @include align-items($value: center);
  background: #e8dcd2 url('../img/404.jpg') center center no-repeat !important;
  background-size: cover !important;
}

.error-404 .message {
  margin: 0 auto 15px auto;
}

.error-404 h1 {
  font-weight: normal;
  font-family: $title-font;
  margin: 0;
  color: #fff;
  line-height: 1.3;
  font-size: 16px;
  font-weight: bold;

}

@media (min-width: 768px) {
  .error-404 h1 {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  .error4-04 h1 {
    font-size: 42px;
  }
}

.error-404 h1 a {
  color: $theme-primary;
  text-decoration: underline;
}

.error-404 .footer {
  display: none;
}