@import "variables";
@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";
@import "owl.carousel/scss/owl.theme.green";
@import "owl.carousel/scss/owl.carousel";
@import "owl.carousel";
@import "mixins";
@import "pages/404";
@import "layout/nav";
@import "layout/footer";
@import "layout/preloader";

.wpml-ls-statics-shortcode_actions {
  display: none !important;
}

/*= input focus effects css
=========================== */
:focus {
  outline: none;
}

::-moz-selection {
  background: #1071CA;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #1071CA;
  color: #fff;
  text-shadow: none;
}

section {
  width: 100%;
  float: left;
}

.vc_empty_space {
  width: 100%;
  float: left;
}

section {
  width: 100%;
  float: left;
}

header.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  .header-image {
    height: 250px !important;
  }
}

.gris {
  background-color: $background;
}

.home {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  min-height: 480px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $black;
  z-index: 1000;
  background-attachment: fixed;
  @include MQ(M) {
    height: calc(100vh - 120px);
  }
  .wrapper {
    position: relative;
    height: 100%;
  }

  .home__wrapper {
    position: absolute;
    top: calc(50% + (80px / 2));
    left: 50%;
    @include transform(translate(-50%, -50%));
    text-align: center;
    padding-bottom: 100px;
    @include MQ(M) {
      //height: calc(100vh - 10px);
    }
  }

  h2 {
    font: 400 2rem $font-family-serif;
    text-transform: uppercase;
    color: $white;
    white-space: nowrap;
    margin: 0 0 15px 0;
    text-shadow: 1px 1px 1px #000;

    @include MQ(SM) {
      font-size: 50px;
    }
  }

  h3 {
    margin: 0;
    font: normal 2rem "Libre Baskerville", cursive;
    line-height: 1;
    color: $color-2;
    //text-shadow: 1px 1px 1px #000;

    @include MQ(SM) {
      font-size: 40px;
    }
  }

  span {
    font-size: 1.25rem;
    line-height: 1.2;
    color: $white;
  }
}

.arrow--home {
  margin-top: 10px;
  @include MQ(SM) {
    margin-top: 40px;
  }
}

.scroll-down {
  position: absolute;
  left: 50%;
  @include transform(translateX(-50%));
  bottom: 50px;
  display: block;
  padding: 5px;
}

@include keyframes (anim--scroll-down) {
  0% {
    @include transform(translateY(-7px));
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    @include transform(translateY(7px));
    opacity: 0;
  }
}

.anim--scroll-down {
  -webkit-animation: anim--scroll-down 1.5s ease-in-out infinite;
  animation: anim--scroll-down 1.5s ease-in-out infinite;
}

.slide-principal {
  @include clearfix;
  margin-top: 0;

  .slide {
    float: left;
    width: 100%;
    background-size: cover;
    background-position: center center;
    color: #fff;
    background-color: $background;
  }

  .cover {
    width: 100%;
    position: relative;
    z-index: 10;
    @include flexbox;
    @include align-items($value: center);
    @include justify-content($value: center);
    height: 100vh;

  }

  .slide-text {
    @media (min-width: 768px) {
      text-align: center;
    }

    .content-text {
      text-align: center;
    }

    h2 {
      margin: 15px 0;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 2px;

      @media (min-width: 1023px) {
        font-size: 24px;
      }

      @media (min-width: 1199px) {
        font-size: 30px;
      }

      em {
        display: block;

      }

    }

  }

  .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .owl-carousel .owl-nav .owl-prev {
    left: -20px;

    @include MQ(M) {
      left: -50px;
    }

    i {
      padding-right: 5px;

      @media(min-width: 768px) {
        padding-right: 5px;
      }

    }

  }

  .owl-carousel .owl-nav .owl-next {
    right: -20px;

    @include MQ(M) {
      right: -50px;
    }

    i {
      padding-left: 5px;

      @media(min-width: 768px) {
        padding-left: 5px;
      }

    }

  }

  .owl-nav .owl-prev {
    left: 20px;

    @include MQ(M) {
      left: 50px;
    }

  }

  .owl-nav .owl-next {
    right: 20px;

    @include MQ(M) {
      right: 50px;
    }

  }

  .custom-icon-left {
    //background-image: url("../img/e-prev.png");
  }

  .custom-icon-right {
    //background-image: url("../img/e-next.png");
  }

}

.parallax-bg {
  background-size: cover;
  background-attachment: fixed;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  .section-title {
    text-align: center;
    font-size: 20px;
    line-height: 1;
    margin: 0;
    color: $white;
    font-family: "Libre Baskerville", cursive;

    @include MQ(SM) {
      font-size: 40px;
    }

  }
}

.custom-font {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  color: $white;
  white-space: nowrap;

  @include MQ(SM) {
    font-size: 50px;
  }
}

.titol-gran {
  margin: 0 auto;
  padding: 30px 0;
  @include font-size(22px);
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  letter-spacing: 5px;
  background: #414545;
  background-image: url(../img/nav.jpg);
  background-size: cover;
  background-position: center center;
}

.linea {
  padding-top: 15px;
  position: relative;

  &:after {
    content: "";
    width: 150px;
    height: 3px;
    background: #D9D9CC;
    position: absolute;
    top: 0;
    left: 0;
  }
}

%border {
  border-top: 5px solid $link;
  padding-top: 15px;
}

.text-destacat {
  margin: 40px 0;
  @extend %border;
}

.espai-title {
  width: 100%;
  float: left;
}

.titol-destacat {
  margin: 30px 0;
  padding: 30px 0 15px 0;
  @include font-size(24px);
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  color: $color-1;
  letter-spacing: 5px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -75px;
    width: 150px;
    height: 3px;
    background-color: $color-1;
  }
}

.featured-section {
  margin: 60px auto 60px auto;
  text-align: center;
  color: rgb(77, 77, 77);

  @include MQ(S) {
    width: 80%;
  }

  @include MQ(SM) {
    padding: 0 50px;
  }

  h3 {
    font-size: 20px;
    line-height: 1;
    margin: 0;
    color: $color-2;
    font-family: "Libre Baskerville", cursive;

    @include MQ(SM) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: uppercase;
    color: $black;
    white-space: nowrap;
    margin: 0 0 15px 0;

    @include MQ(SM) {
      font-size: 50px;
    }

  }
}

.titol-pagina {
  width: 100%;
  float: left;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  background-color: $background;

  .cover {
    width: 100%;
    float: left;
    min-height: 50vh;
    @include flexbox;
    @include align-items($value: center);
    @include justify-content($value: center);

    h1,
    h2,
    h3 {
      text-transform: uppercase;
      text-align: center;
      margin: 70px 0;
      letter-spacing: 5px;
    }

  }
}

.llistat {
  @include flexbox;
  @include flex-wrap(wrap);
  @include flex-direction($value: row);
  @include align-items($value: flex-start);
  @include justify-content(flex-start);
  margin: 0px auto 50px auto;
  width: 100%;

  .categoria {
    width: 50%;
    display: block;
    color: $main-text;
    margin: 10px 0 30px 0;
    text-align: center;
    min-height: 150px;
    padding: 0 15px;

    @include MQ(SM) {
      width: 50%;
    }

    @include MQ(SM) {
      width: 33.3333%;
    }

    @include MQ(M) {
      width: 25%;
    }

    &:hover,
    &:focus {
      color: $link;
      text-decoration: none;
    }

    .i-cat {
      display: block;
      margin: 0 auto;

      img {
        margin: 0 auto;
        max-width: 90%;
      }
    }

    .i-text {
      display: block;
      padding: 0 15px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.header-image {
  width: 100%;
  float: left;
  height: 300px;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-bottom: 50px;

  @include MQ(SM) {
    height: 400px;
  }

  .single-image {
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -100px;

    img {
      width: 100%;
      height: auto;
      float: left;
      display: inline-block;
    }
  }

  .single-text {
    width: 100%;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;

    .page-title {
      margin: 50px 0;
      padding: 15px 0;
      font: 400 2rem $font-family-serif;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      background-color: rgba(5, 180, 230, 0.5);
      mix-blend-mode: hard-light;
      @include MQ(SM) {
        font-size: 3rem;
      }
    }
  }
}

.bg-contain .header-image {
  background-attachment: inherit;
}

.single-especialidad {
  .header-image {
    @media(min-width: 1024px) {
      margin-top: -35px !important;
    }
  }
}

.logos {
  padding: 0;

  .logo {
    margin: 0;
    width: 100%;
    padding: 15px;
    height: auto;
    text-align: center;

    a {

      &:hover,
      &:focus {
        text-decoration: none;
      }

    }

    img {
      max-height: 100px;
      width: auto;
      margin: 0 auto;
    }

  }

}

.relacionados {
  margin: 0 auto 30px auto;
  width: 100%;
  float: left;

  .owl-stage {
    @include flexbox;
    @include flex-direction($value: row);
    @include align-items($value: stretch);
    padding: 5px 0;
  }

  .relacionado {
    width: 100%;
    float: left;
    padding: 0;

    .box {
      width: 100%;
      float: left;
      position: relative;

    }

    .cover {
      width: 100%;
      height: 0;
      min-height: 0;
      padding-bottom: 70%;
      overflow: hidden;
    }

    .image {
      width: 100%;
      height: 0;
      min-height: 0;
      padding-bottom: 70%;
      background-size: cover;
      background-position: center center;
      @include transition(all linear .2s);

    }

    .text {
      color: #111;
      margin: 0 auto;
      text-align: center;
      padding: 15px;
      @include transition(all linear .2s);

      h3 {
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 1.3;
        text-transform: uppercase;
      }
    }

    a {
      display: block;

      &:hover,
      &:focus {
        text-decoration: none;

        .image {
          @include transform(scale(1.1));
        }

        .text {
          color: $link;
        }
      }
    }

    &:hover {
    }
  }

  .owl-dots {
    position: relative;

    .owl-dot span {
      background: #eee;
    }
  }
}

.featured-products {
  margin: 60px auto;
  clear: both;

  .owl-stage {
    @include flexbox;
    @include flex-wrap(wrap);
    @include flex-direction($value: row);
    @include align-items($value: flex-start);
    @include justify-content(flex-start);
  }

  .categoria {
    width: 100%;
    display: block;
    color: $main-text;
    margin: 0;
    text-align: center;
    min-height: 150px;
    padding: 0;

    &:hover,
    &:focus {
      color: $link;
      text-decoration: none;
    }

    .i-cat {
      display: block;
      margin: 0 auto;

      img {
        margin: 0 auto;
      }
    }

    .i-text {
      display: block;
      padding: 15px 15px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .owl-dots {
    position: relative;
    margin-top: 50px !important;
    margin-bottom: 0 !important;
  }

  .owl-dots .owl-dot span {
    background-color: #ccc;
  }

}

.galeria-fotos {
  clear: both;
  margin: 15px auto;

  .foto {
    width: 100%;
    height: 0;
    padding-bottom: 56.2%;
    background-position: center center;
    background-size: cover;
    margin: 0;
    position: relative;

    a {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

    }
  }

}

.header-space {

  width: 100%;
  float: left;
  height: 60px;

}

.img-principal {
  width: 100%;
  float: left;
  height: 300px;
  position: relative;
  background-size: cover;
  background-position: center center;

  @include MQ(SM) {
    height: 0;
    min-height: 0;
    padding-bottom: 30.07752%;
  }

  .img-gran {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: table;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #fff;

    h2 {
      display: table-cell;
      vertical-align: middle;
      font-weight: 900;
      font-size: 50px;
      padding: 0 0 50px 0;

      @include MQ(SM) {
        font-size: 60px;
      }

      @include MQ(M) {
        font-size: 90px;
      }

      @include MQ(L) {
        font-size: 110px;
      }
    }
  }
}

.bg-video-full {
  margin: 0;
  width: 100% !important;
  float: left;
  overflow: hidden;
  height: 0;
  min-height: 0;
  padding-bottom: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media(min-width: 768px) {
    padding-bottom: 56.25%;
  }

  //position: relative;
  //z-index: 999;
  .video-full {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: none;

    @media(min-width: 768px) {
      display: block;
    }
  }

}

.alergenos {
  width: 100%;
  float: left;
  margin: 0 0 35px 0;
  padding: 15px 0;
  border-top: 1px dotted $main-text;

  a {
    @include flexbox;
    @include align-items($value: center);
    width: 100%;
    color: $main-text;

    &:hover,
    &:focus {
      color: $link;
      text-decoration: none;
    }
  }

  .fa {

    font-size: 30px;
    padding-right: 10px;
  }

  .info {
  }
}

.video-img {
  margin: 30px 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  min-height: 0;
  width: 100%;
  background-color: #000;

  .play {
    @include center($xy: xy);
    width: 60px;
    height: 60px;
    background-color: transparent;
    background-image: url("../img/play.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    position: absolute;
    text-align: center;
    z-index: 30;
  }

  img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    cursor: pointer;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
  }
}

.last-news {
  margin: 0;
  clear: both;

  .owl-stage {
    @include flexbox;
    @include flex-direction($value: row);
    @include align-items($value: stretch);
    padding: 5px 0;
  }

  .post {
    text-align: center;

    margin: 0;
    height: 100%;
    background-color: #fff;

    a {
      //@include flexbox;
      //@include flex-direction($value: column);
      height: 100%;

      &:hover,
      &:focus {
        text-decoration: none;

        .text {

          color: #fff;
          background: $theme-secondary;

          h3 {
            color: #fff;
          }

        }

        .fecha {
          color: #fff;
        }

        .cover {
          //transform: scale(1.1);
          //background-size: 110%;
        }

      }

    }

    .image {
      height: 0;
      min-height: 0;
      padding-bottom: 56.25%;
      position: relative;
      overflow: hidden;
      width: 100%;
      float: left;
      background-color: #f9f9f9;
    }

    .fecha {
      text-transform: uppercase;
      color: #A6A8AB;
      margin: 0;
      font-family: $title-font;
      @include transition(all linear .1s);
    }

    .cover {
      height: 0;
      min-height: 0;
      padding-bottom: 56.25%;
      background-position: center center;
      background-size: cover;
      position: relative;
      overflow: hidden;
      @include transition(all linear .2s);
    }

    .text {
      width: 100%;
      float: left;
      min-height: 175px;
      height: 100%;
      padding: 10px 15px 30px 15px;
      background: #fff;
      color: $black;
      text-align: left;
      @include transition(all linear .2s);

      h3 {
        margin: 10px auto 10px auto;
        color: $theme-primary;
        text-transform: uppercase;
        font-weight: bold;
        @include transition(all linear 0s);
        font-size: 20px;

        @include MQ(L) {
          font-size: 20px;
        }
      }

    }

  }

}

.archive-blog {
  margin: 0 -15px;
  clear: both;

  @include flexbox;
  @include flex-wrap($value: wrap);
  @include align-items($value: stretch);
  @include align-content($value: flex-start);
  @include justify-content($value: flex-start);

  .post-cover {
    width: 100%;
    padding: 15px;
    position: relative;

    @include MQ(SM) {
      width: 50%;
    }

    @include MQ(L) {
      width: 33.3333%;
    }

  }

  .post {
    text-align: center;
    margin: 0;
    background-color: #fff;
    position: relative;

    a {
      height: 100%;

      &:hover,
      &:focus {
        text-decoration: none;

        .text {
          color: #fff;
          background: $theme-secondary;

          h3 {
            color: #fff;
          }

        }

        .fecha {
          color: #fff;
        }

      }

    }

    .image {
      height: 0;
      min-height: 0;
      padding-bottom: 56.25%;
      position: relative;
      overflow: hidden;
      width: 100%;
      float: left;
      background-color: #f9f9f9;
    }

    .fecha {
      text-transform: uppercase;
      color: #A6A8AB;
      margin: 0;
      font-family: $title-font;
      @include transition(all linear .1s);
    }

    .cover {
      height: 0;
      min-height: 0;
      padding-bottom: 56.25%;
      background-position: center center;
      background-size: cover;
      position: relative;
      overflow: hidden;
      @include transition(all linear .2s);
    }

    .text {
      width: 100%;
      float: left;
      height: 100%;
      padding: 10px 15px 30px 15px;
      background: #fff;
      color: $black;
      text-align: left;
      @include transition(all linear .2s);

      h3 {
        margin: 10px auto 10px auto;
        color: $theme-primary;
        text-transform: uppercase;
        font-weight: bold;
        @include transition(all linear 0s);
        font-size: 20px;

        @include MQ(L) {
          font-size: 20px;
        }
      }

    }

  }

}

.single-p {
  text-align: center;
  width: 100%;
  float: left;

  .post {
    width: 100%;
    float: left;

    .embed-responsive {
      margin: 30px auto;
    }
  }

  .text {
    max-width: 600px;
    margin: 0 auto;
  }

  .share {
    margin: 30px auto;
    list-style: none;
    text-align: left;
    display: inline-block;
    font-size: 30px;

    li {
      display: inline-block;
      margin: 5px 10px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

}

.map-item {
  width: 100%;
  height: 450px;
  float: left;

  @include MQ(L) {
    height: 650px;
  }
}

.mapa {
  background-color: #f8f8f8;
  background-position: top center !important;
  background-repeat: no-repeat !important;

  .titulodestacado2 {
    text-align: left;

    .title {
      display: inline-block;

      &:after {
        left: 0;
        margin-left: 0;
      }
    }
  }
}

.info_content {

  h2,
  h3 {
    font-size: 18px;
    margin: 10px 0 5px 0;
    color: $link;
  }

  p {
    font-size: 14px;
    margin: 5px 0 15px 0;
    min-width: 280px;
    line-height: 1.5;
    font-weight: bold;
    color: $main-text;
  }

  .btn {
    margin-top: 10px;
  }
}

.formulari {
  margin: 15px auto 60px auto;
  width: 100%;
  float: left;

  .wpcf7-submit {
    float: right;

  }

}

span.wpcf7-list-item {
  margin: 10px 5px 10px 0 !important;
}

.form-group {
  width: 100%;
  float: left;
  margin: 0;

  p {
    display: block;
    float: left;
  }

  label {

    color: $main-text;
    margin: 10px 0;
  }

  :focus {
    outline: none;
  }

}

.form-control {
  @include border-radius(0);
  //color: $gris-clar;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 10px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.075);
  color: $main-text;
  font-size: 16px;
  //@include transition(all linear .2s);
  position: relative;

  &:hover,
  &:focus {
    //border: 1px solid $theme-primary;
    color: $main-text;
    outline: none;
  }

  &:focus {

    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    //color: $theme-primary;
  }

  &:-moz-placeholder {

    /* Firefox 18- */
    //color: $theme-primary;
  }

  &::-moz-placeholder {

    /* Firefox 19+ */
    //color: $theme-primary;
  }

  &:-ms-input-placeholder {
    //color: $theme-primary;
  }

}

textarea {
  @include border-radius(0);
  //color: $gris-clar;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 10px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.075);
  color: $main-text;
  @include transition(all linear .2s);
  width: 100%;
  font-size: 16px;

  position: relative;
  min-height: 200px;

  &:hover,
  &:focus {
    //border: 1px solid $theme-primary;
    color: $main-text;
    outline: none;
  }
}

:focus {
  outline: none;
}

.magia {
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 15px;
  z-index: 100;
  background: $color-4;
}

.effect-11 {
  border: 1px solid $color-1 !important;
  position: relative !important;
  background: transparent !important;
  z-index: 11 !important;
}

.error .effect-11 {
  border: 1px solid red !important;
}

.effect-11 ~ .focus-bg {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 0 !important;
  height: 100% !important;
  background-color: $background !important;
  transition: 0.3s !important;
  z-index: 8 !important;
}

.effect-11:focus ~ .focus-bg {
  transition: 0.3s !important;
  width: 100% !important;
}

div.wpcf7-response-output {
  margin: 0 !important;
  padding: 15px !important;
  border: 0 !important;
}

span.wpcf7-not-valid-tip {
  background-color: transparent !important;
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 10px;
}

.popup-block {
  background: transparent;
  color: #fff;
  padding: 30px 15px 15px 15px;
  text-align: left;
  max-width: 1200px;
  margin: 40px auto;
  position: relative;

  h3 {
    margin-top: 0;
    font-family: $title-font;
    font-weight: bold;
    font-size: 18px;

    @include MQ(SM) {
      font-size: 22px;
    }

    @include MQ(M) {
      font-size: 28px;
    }

    @include MQ(L) {
      font-size: 38px;
    }
  }

  .mfp-close {
    color: #fff !important;
    right: 0px;
    top: -10px;
  }

  .custom-post {
    @include flexbox;
    @include flex-direction($value: row);
    @include flex-wrap($value: wrap);
  }

  .foto {
    width: 100%;

    @include MQ(SM) {
      width: 66.6666666667%;
    }

  }

  .text {
    width: 100%;
    background-color: #483D4A;
    padding: 15px;

    @include MQ(SM) {
      width: 33.333333%;
    }

    .fa {
      width: 20px;
    }

    ul {
      list-style: none;

      li {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.popup-block-post {
  background: #E7ECEC;

  padding: 30px 15px 15px 15px;
  text-align: left;
  max-width: 1200px;
  margin: 40px auto;
  position: relative;

  h3 {
    margin-top: 0;
    font-family: $title-font;
    font-weight: bold;
    font-size: 16px;

    @include MQ(SM) {
      font-size: 18px;
    }

    @include MQ(M) {
      font-size: 22px;
    }

    @include MQ(L) {
      font-size: 28px;
    }
  }

  .mfp-close {
    color: #000 !important;
    right: 0px;
    top: 0;
  }

  .custom-post {
    @include flexbox;
    @include flex-direction($value: row);
    @include flex-wrap($value: wrap);
  }

  .foto {
    width: 100%;

    @include MQ(SM) {
      width: 50%;
    }

  }

  .text {
    width: 100%;
    background-color: transparent;
    padding: 0 15px 0 30px;

    @include MQ(SM) {
      width: 50%;
    }

    .fa {
      width: 20px;
    }

    ul {
      list-style: none;

      li {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.contact {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 75px;
  text-align: center;
  @include MQ(M) {
    background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url("../img/bg-contact.jpg");
    background-size: cover;
  }
}

.arrow--subscribe,
.form--subscribe {
  margin-top: 45px;
}

.input--subscribe {
  width: 200px;
  height: 40px;
  padding-left: 20px;
  border: 1px solid $color-3;
  //font-family: $serif-font-stack;
  font-size: 15px;
  color: #41403f;
  background-color: rgba(255, 255, 255, .5);

  @include MQ(SM) {
    width: 270px;
    height: 50px;
    padding-left: 20px;
  }
}

.submit--subscribe {
  //margin-left: calc(26px - 4px);
  border: none;
}

.submit--subscribe:hover {
  cursor: pointer;
}

.contact.button {
  position: relative;
  display: inline-block;
  margin: 15px auto 0 auto;
  width: 140px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  background-color: #e4bb7a;

  font-weight: 600;

  @include MQ(SM) {
    margin: 0 auto 0 20px;
    width: 172px;
    height: 50px;
    line-height: 50px;
  }
}

.realted-file {
  display: block;
  padding: 5px 0;
}